import React, { useEffect, useCallback, useState, FC } from 'react'
import { useLocation } from 'react-router-dom'

export const useAdjustBanner = (): {
  adjustBannerVisible: boolean
  hideAdjustBanner: () => void
  showAdjustBanner: () => void
} => {
  const [banner, setBanner] = useState<Element | null>(null)
  const [show, setShow] = useState(true)

  useEffect(() => {
    if (!document) return

    const parent = document.body
    const selector = "[class^='adjust-smart-banner']"

    // If the element exist already, deal with it directly.
    const banner = document.querySelector(selector)
    if (banner) return setBanner(banner)

    if (!MutationObserver) return

    // If it wasn't found, set up an observer to listen for it to be added
    const observer = new MutationObserver((mutationList) => {
      const matchingNodes = mutationList.reduce((memo, mutation) => {
        return memo.concat(
          Array.from(mutation.addedNodes).filter((node) => node instanceof Element && node.matches(selector))
        )
      }, [] as Array<Node>)

      // There can be only one.......
      if (matchingNodes.length > 0) setBanner(matchingNodes[0] as Element)
    })
    observer.observe(parent, { attributes: true, childList: true })

    return () => {
      if (observer) {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    if (!window || !banner) return

    // show or hide banner
    if (!banner.classList.contains('tw-hidden') && !show) {
      banner.classList.remove('tw-block')
      banner.classList.add('tw-hidden')
    } else if (banner.classList.contains('tw-hidden') && show) {
      banner.classList.remove('tw-hidden')
      banner.classList.add('tw-block')
    }
  }, [banner, show])

  const hideAdjustBanner = useCallback(() => {
    setShow(false)
  }, [])

  const showAdjustBanner = useCallback(() => {
    setShow(true)
  }, [])

  return {
    adjustBannerVisible: show,
    hideAdjustBanner,
    showAdjustBanner,
  }
}

// Blacklist.
const HIDE_BANNER_ON = [
  // All onboarding steps.
  '/onboarding*',
  '/resources/frequently-asked-questions',
  '/resources/referral-frequently-asked-questions',
  '/users/password*',
].map((path) => path.replace('/', '/').replace('*', '.*'))

const ShowOrHideAdjustBanner: FC<{ pathname: string }> = ({ pathname }) => {
  const { hideAdjustBanner, showAdjustBanner } = useAdjustBanner()
  useEffect(() => {
    if (!window) return

    const regex = new RegExp(`^(${HIDE_BANNER_ON.join('|')})`)
    const shouldHideBanner = regex.test(pathname)

    if (shouldHideBanner) {
      hideAdjustBanner()
    } else {
      showAdjustBanner()
    }
  }, [pathname, hideAdjustBanner, showAdjustBanner])

  return null
}

export const AutoShowOrHideAdjustBanner: FC = () => {
  const location = useLocation()

  return <ShowOrHideAdjustBanner pathname={location.pathname} />
}

export default ShowOrHideAdjustBanner
