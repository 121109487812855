import cx from 'classnames'
import React, { FC } from 'react'

interface XCircleProps {
  className?: string
}

const XCircle: FC<XCircleProps> = ({ className }) => (
  <svg className={cx('x-circle', className)} fill="none" stroke="currentColor" viewBox="0 0 24 24">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
    ></path>
  </svg>
)

export default XCircle
