import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <linearGradient id="property-plan_fallback_a" gradientUnits="userSpaceOnUse" x1="24" x2="24" y1="44" y2="4">
      <stop offset="0" stopColor="#23473a" />
      <stop offset=".02" stopColor="#254a3d" />
      <stop offset=".16" stopColor="#326553" />
      <stop offset=".32" stopColor="#3d7a64" />
      <stop offset=".49" stopColor="#448971" />
      <stop offset=".69" stopColor="#499278" />
      <stop offset="1" stopColor="#4a957a" />
    </linearGradient>
    <path
      className="pgicon_secondary"
      d="M29.08 36.13a9.45 9.45 0 0 0-.19 1.87 5 5 0 0 1-1.43 3.5A4.87 4.87 0 0 1 24 43a4.93 4.93 0 0 1-4.89-5 9.45 9.45 0 0 0-.19-1.91A9 9 0 0 0 24 37.54a8.88 8.88 0 0 0 5.08-1.41zM24 16.41a6.78 6.78 0 0 0-4.5 11.73l.14.12.31.35a14 14 0 0 1 2.83 5.1 10.19 10.19 0 0 0 1.24.07 11.56 11.56 0 0 0 1.19-.06 14.24 14.24 0 0 1 2.89-5.17l.26-.29.14-.12A6.78 6.78 0 0 0 24 16.41z"
      fill="#9bd69a"
      opacity=".75"
    />
    <path
      className="pgicon_primary"
      d="M24 9a1 1 0 0 1-1-1V5a1 1 0 0 1 2 0v3a1 1 0 0 1-1 1zM10 22a1 1 0 0 0-1-1H7a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1zm32 0a1 1 0 0 0-1-1h-2a1 1 0 0 0 0 2h2a1 1 0 0 0 1-1zm-28.75-8.74a1 1 0 0 0 0-1.42l-2.63-2.63a1 1 0 0 0-1.42 0 1 1 0 0 0 0 1.41l2.64 2.64a1 1 0 0 0 1.41 0zm22.91 0l2.64-2.64a1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0l-2.63 2.63a1 1 0 0 0 1.41 1.42zm2.55 22.45a1 1 0 0 0 0-1.41l-2-2a1 1 0 0 0-1.42 1.42l2 2a1 1 0 0 0 1.41 0zm-28 0l2-2a1 1 0 0 0 0-1.42 1 1 0 0 0-1.41 0l-2 2a1 1 0 0 0 0 1.41 1 1 0 0 0 1.42 0zM36 23.16a12.36 12.36 0 0 1-3.87 9l-.08.08a8.92 8.92 0 0 0-1.84 3.48 3.19 3.19 0 0 0-.13.59 8.63 8.63 0 0 0-.19 1.69 6 6 0 0 1-1.72 4.2A5.84 5.84 0 0 1 24 44a5.93 5.93 0 0 1-5.89-6 9 9 0 0 0-.17-1.71 3.34 3.34 0 0 0-.14-.6 8.67 8.67 0 0 0-1.87-3.55l-.11-.13A12.08 12.08 0 1 1 36 23.16zM27.89 38v-.19a11.29 11.29 0 0 1-7.8 0v.2A3.94 3.94 0 0 0 24 42a3.86 3.86 0 0 0 2.75-1.16A4 4 0 0 0 27.89 38zM34 23.16a10 10 0 1 0-20 0 10.17 10.17 0 0 0 3.24 7.49l.19.2a10.9 10.9 0 0 1 2.29 4.31q0 .15.09.33a8.33 8.33 0 0 0 4.21 1 8.28 8.28 0 0 0 4.17-1 3 3 0 0 1 .11-.4 10.67 10.67 0 0 1 2.26-4.23l.14-.16a10.23 10.23 0 0 0 3.3-7.54z"
      fill="url(#property-plan_fallback_a)"
    />
  </svg>
)

export default Svg
