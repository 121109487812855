import React, { FC, PropsWithChildren, useEffect, useState } from 'react'

interface Props {
  className?: string
  uri?: string
}

const ExternalSvg: FC<PropsWithChildren<Props>> = ({ className, uri, children }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const [svgContent, setSvgContent] = useState<string>('')
  const [svgViewBox, setSvgViewBox] = useState<string | undefined>()

  // Immediately fetch any info.
  useEffect(() => {
    if (!uri) {
      setIsLoaded(true)
      return
    }
    let cancel = false

    const download = async () => {
      const response = await fetch(uri)
      if (cancel) return
      // Check for errors.
      if (response.status < 200 || response.status >= 300) {
        setIsLoaded(true)
        throw new Error(`Failed to fetch svg "#{uri}"`)
      }

      const s_svg = await response.text()
      if (cancel) return

      // Extract the innerHTML and the viewbox.
      const template = document.createElement('template')
      template.innerHTML = s_svg.trim()
      const svg = template.content.children[0]
      setSvgViewBox(svg.getAttribute('viewBox') || undefined)
      setSvgContent(svg.innerHTML || '')
      template.remove()
      setIsLoaded(true)
    }
    download()
    return () => {
      cancel = true
    }
  }, [uri])

  // Show our SVG if we have it and we're ready
  if (svgContent && isLoaded)
    return <svg className={className} viewBox={svgViewBox} dangerouslySetInnerHTML={{ __html: svgContent }}></svg>

  // Show the fallback if an uri was not provided, or we're done attempting
  // to load it
  if (children && (!uri || isLoaded)) return <>{children}</>

  // Otherwise blank is fine until we know we're ready
  return <svg className={className} />
}
export default ExternalSvg
