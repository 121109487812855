import React, { FC, useEffect, useState, useCallback } from 'react'
import classNames from 'classnames'

import styles from './WistiaVideo.module.scss'
import { track } from 'utils/analytics'

interface Props {
  videoId: string
  className?: string
}

declare global {
  interface Window {
    Wistia: { api: (id: string) => WistiaVideo }
    _wq: Array<any>
  }
}
interface WistiaVideo {
  remove: () => void
}

const WISTIA_SCRIPT_ID = 'wistia_external_script'

const WistiaVideo: FC<Props> = ({ videoId, className }) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [paddingTop, setPaddingTop] = useState<number>(56.25)

  useEffect(() => {
    // Install the external script, if it is not yet defined
    if (!document.getElementById(WISTIA_SCRIPT_ID)) {
      const permScript = document.createElement('script')
      permScript.id = WISTIA_SCRIPT_ID
      permScript.async = true
      permScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      document.body.appendChild(permScript)
    }

    // We always need the video script
    const vidScript = document.createElement('script')
    vidScript.async = true
    vidScript.src = `https://fast.wistia.com/embed/medias/${videoId}.jsonp`
    document.body.appendChild(vidScript)

    window._wq = window._wq || []
    window._wq.push({
      id: '_all',
      onReady: (video) => {
        const asset = video.data.media.assets[0]
        if (video.data.media.hashedId !== videoId) return
        setPaddingTop((asset.height / asset.width) * 100)
        video.bind('play', () => {
          track('play video', { videoId })
        })
        video.bind('end', () => {
          track('finish video', { videoId })
        })
      },
    })

    return () => {
      // Stop playback
      if (window && window.Wistia) {
        const video = window.Wistia.api(videoId)
        if (video) video.remove()
      }

      // Drop the video script when we leave.
      vidScript.remove()
    }
  }, [videoId])

  const onLoad = useCallback(() => setIsLoaded(true), [])

  return (
    <div
      className={classNames('wistia_responsive_padding', styles.wistiaVideo, className)}
      style={{ paddingTop: `${paddingTop}%` }}
    >
      <div className={classNames('wistia_responsive_wrapper', styles.reponsiveWrapper)}>
        <div className={classNames(`wistia_embed wistia_async_${videoId} videoFoam=true`, styles.embed)}>
          <div className={classNames('wistia_swatch', styles.swatch, isLoaded ? styles.loaded : null)}>
            <img
              src={`https://fast.wistia.com/embed/medias/${videoId}/swatch`}
              alt=""
              aria-hidden="true"
              onLoad={onLoad}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default WistiaVideo
