import { useEffect, useState } from 'react'

export const useWindowHeight = (): number | undefined => {
  const [windowHeight, setWindowHeight] = useState<number | undefined>()

  useEffect(() => {
    const handleResize = () => setWindowHeight(window.visualViewport?.height)

    handleResize()

    window.visualViewport?.addEventListener('resize', handleResize)
    return () => window.visualViewport?.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (windowHeight) document.documentElement.style.setProperty('--wh', `${windowHeight}px`)
  }, [windowHeight])

  return windowHeight
}
