import React, { FC, useCallback, useState, useEffect, useRef } from 'react'

import Lightbox from 'components/Lightbox'

import PlayIcon from 'svgs/play-icon'

import styles from './styles.module.scss'

interface Props {
  id: string
  showCta?: boolean
  onClose: () => void
}
const CaseStudyViewer: FC<Props> = ({ id, showCta = false, onClose }) => {
  const [close, setClose] = useState(false)
  const iFramRef = useRef<HTMLIFrameElement>()
  const setRef = useCallback((iFrame: HTMLIFrameElement) => {
    if (!iFrame) return
    iFramRef.current = iFrame

    function listenForMessages() {
      const iWindow = iFrame.contentWindow as any
      if (!iWindow) return

      // Since the case studies were designed to run in the mobile app,
      // we can make sure of the event system to find out when the
      // close button is being hit.
      iWindow.ReactNativeWebView = {
        postMessage: (message: string) => {
          if (message == 'close') setClose(true)
          else if (message.startsWith('click_cta')) {
            const link = JSON.parse(message.replace(/click_cta[:]/, ''))
            if (!link) return

            if (link.target === '_blank') {
              window.open(link.url, '_blank')?.focus()
            } else {
              window.location.href = link.url || ''
            }
          }
        },
      }
    }
    if (iFrame.contentDocument?.readyState == 'complete') listenForMessages()
    iFrame.addEventListener('load', listenForMessages)
  }, [])

  useEffect(() => {
    // We want to disable the intercom widget when our viewer is up.
    const intercomClasses = '.intercom-namespace, .intercom-lightweight-app'
    const intercomElements = document.querySelectorAll(intercomClasses)
    intercomElements.forEach((el) => {
      el.setAttribute('style', 'display: none')
    })

    return () => {
      intercomElements.forEach((el) => {
        el.removeAttribute('style')
      })
    }
  }, [])

  useEffect(() => {
    // Something odd about the interaction of the 'click' coming through
    // the iframe and then closing the iframe causes the pointer events
    // to not be consumed, and bubble through to the main window after
    // the component is destroyed. This can cause an unintented click
    // behind the lightbox.
    // I'm adding a slight delay to mitigate that.
    const timeout = setTimeout(() => {
      if (close) onClose()
    }, 100)

    return () => clearTimeout(timeout)
  }, [close, onClose])

  // Any clicks outside of the case study should close the lightbox.
  useEffect(() => {
    if (!window) return

    const handleClose = () => setClose(true)

    window.addEventListener('click', handleClose)
    return () => {
      if (window) window.removeEventListener('click', handleClose)
    }
  }, [])

  const handleClickLeft = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!iFramRef?.current) return

    const iWindow = iFramRef.current.contentWindow as any
    if (!iWindow) return

    e.preventDefault()
    e.stopPropagation()

    iWindow.CaseStudyStory.back()
  }, [])

  const handleClickRight = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    if (!iFramRef?.current) return

    const iWindow = iFramRef.current.contentWindow as any
    if (!iWindow) return

    e.preventDefault()
    e.stopPropagation()

    iWindow.CaseStudyStory.forward()
  }, [])

  return (
    <Lightbox onClose={onClose} className={styles.caseStudyViewer}>
      <div className={styles.psudoPhone}>
        <iframe
          title="Project Case Study"
          src={`/project-case-studies/${id}?in_app_story${showCta ? '&show_cta' : ''}`}
          ref={setRef}
        />
      </div>
      <button type="button" className={styles.left} onClick={handleClickLeft}>
        <PlayIcon />
      </button>
      <button type="button" className={styles.right} onClick={handleClickRight}>
        <PlayIcon />
      </button>
    </Lightbox>
  )
}

export default CaseStudyViewer
