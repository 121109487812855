import React, { FC } from 'react'

interface BedProps {
  className?: string
}

const Bed: FC<BedProps> = ({ className }) => (
  <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="none">
    <path
      d="M13.3333 7.66665V4.66665C13.3333 3.93331 12.7333 3.33331 12 3.33331H3.99998C3.26665 3.33331 2.66665 3.93331 2.66665 4.66665V7.66665C1.93331 7.66665 1.33331 8.26665 1.33331 8.99998V14.3333H2.21998L2.66665 15.6666H3.33331L3.77998 14.3333H12.2266L12.6666 15.6666H13.3333L13.78 14.3333H14.6666V8.99998C14.6666 8.26665 14.0666 7.66665 13.3333 7.66665ZM7.33331 7.66665H3.99998V4.66665H7.33331V7.66665ZM12 7.66665H8.66665V4.66665H12V7.66665Z"
      fill="currentColor"
    />
  </svg>
)

export default Bed
