import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import type { RedirectProps } from 'react-router-dom'
import classNames from 'classnames'

import { useOverlay } from 'layouts/components/OverlayMount'
import TabTrap from './TabTrap'

import styles from './styles.module.scss'

interface Props {
  className?: string
  placementControlClassName?: string
  onClose?: (() => void) | RedirectProps
  position?: 'center' | 'top' | 'bottom'
  blurredBackground?: boolean
}

const Lightbox: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  placementControlClassName,
  onClose,
  position = 'center',
  blurredBackground,
}) => {
  const { mount } = useOverlay()
  const [closeRequested, setCloseRequested] = useState(false)

  // When the lightbox is up, the main scrollbar shouldn't
  // be showing. We'll make it go away, but we don't want the window
  // contents to shift when we do so, so we'll add some padding when we
  // do it. But.. this has to happen once, and before we adjust our document
  // overflow.
  const [sbWidth] = useState(window.innerWidth - document.documentElement.clientWidth)

  // When the lightbox is up, we want the default scroll to be paused.
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    document.body.style.paddingRight = `${sbWidth}px`

    return () => {
      document.body.style.removeProperty('overflow')
      document.body.style.removeProperty('padding-right')
    }
  }, [sbWidth])

  // Detect 'escape' button being hit to request a close on the lightbox.
  useEffect(() => {
    const onEscDown = (e: KeyboardEvent) => {
      if ((e.code == 'Escape' || e.key == 'Escape') && onClose) {
        e.preventDefault()
        if (typeof onClose === 'function') onClose()
        else setCloseRequested(true)
      }
    }

    if (window && onClose) window.addEventListener('keydown', onEscDown, true)
    return () => {
      if (window) window.removeEventListener('keydown', onEscDown, true)
    }
  }, [onClose])

  if (closeRequested && typeof onClose === 'object') {
    return <Redirect {...onClose} />
  }

  return (
    <>
      {mount(
        <div
          className={classNames(styles.lightbox, { [styles.blurredBackground]: blurredBackground }, styles[position])}
        >
          <div className={styles.base}>
            <div className={styles.positioner}>
              <TabTrap className={classNames(styles.trap, placementControlClassName)}>
                <div className={classNames(styles.container, className)}>{children}</div>
              </TabTrap>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Lightbox
