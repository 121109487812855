import React, { FC } from 'react'

import cx from 'classnames'

interface CoreDividerProps {
  className?: string
}

const Section: FC<CoreDividerProps> = ({ className }) => {
  return <hr className={cx('tw-bg-neutrals-100 tw-h-2 tw-border-0', className)} />
}

const Full: FC<CoreDividerProps> = ({ className }) => {
  return <hr className={cx('tw-bg-neutrals-200 tw-h-px tw-border-0', className)} />
}

const Indent: FC<CoreDividerProps> = ({ className }) => {
  return <hr className={cx('tw-bg-neutrals-200 tw-h-px tw-ml-6 tw-border-0', className)} />
}

const Center: FC<CoreDividerProps> = ({ className }) => {
  return <hr className={cx('tw-bg-neutrals-200 tw-h-px tw-mx-6 tw-border-0', className)} />
}

const CoreDivider = {
  Section: Section,
  Full: Full,
  Indent: Indent,
  Center: Center,
}

export default CoreDivider
