import React, { FC, useState, useEffect } from 'react'
import { Redirect, useRouteMatch, useLocation } from 'react-router-dom'

interface Props {
  /* One or more query keys that we want to grab */
  query: string | Array<string>
  /* Any keys we grab will be emitted with their value(s) */
  onHandle: (key: string, value?: Array<string>) => void
  /* By default, we consume the query parameter. Set this if you don't want to */
  preserve?: boolean
}

interface Queries {
  [key: string]: Array<string>
}

function getPath(pathRoot: string, query?: string | null) {
  if (query) return `${pathRoot}?${query}`
  return pathRoot
}

const HandleURLQuery: FC<Props> = ({ query, onHandle, preserve }) => {
  const match = useRouteMatch()
  const location = useLocation()
  const [handleQueries, setHandleQueries] = useState<Queries>({})
  const [newQuery, setNewQuery] = useState(location.search)

  // Pull off queries, recalculate path.
  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const queries = query instanceof Array ? query : [query]

    const newHandleQueries: Queries = {}
    for (const key of queries) {
      if (search.has(key)) {
        newHandleQueries[key] = search.getAll(key)

        // Remove consumed search params, if needed
        if (!preserve) search.delete(key)
      }
    }

    setHandleQueries(newHandleQueries)
    setNewQuery(search.toString())
  }, [query, preserve, location.search])

  // Invoke callback if we found our handlers.
  useEffect(() => {
    if (onHandle && Object.keys(handleQueries).length) {
      Object.keys(handleQueries).forEach((key) => {
        onHandle(key, handleQueries[key])
      })
      // Only let them fire once.
      setHandleQueries({})
    }
  }, [onHandle, handleQueries])

  if (newQuery != location.search) {
    return <Redirect to={getPath(match.url, newQuery)} />
  }
  return null
}

export default HandleURLQuery
