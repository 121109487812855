import React, { FC } from 'react'

interface BathProps {
  className?: string
}

const Bath: FC<BathProps> = ({ className }) => (
  <svg width="16" height="16" className={className} viewBox="0 0 16 16" fill="none">
    <path
      d="M4.66665 5.99998C5.40303 5.99998 5.99998 5.40303 5.99998 4.66665C5.99998 3.93027 5.40303 3.33331 4.66665 3.33331C3.93027 3.33331 3.33331 3.93027 3.33331 4.66665C3.33331 5.40303 3.93027 5.99998 4.66665 5.99998Z"
      fill="currentColor"
    />
    <path
      d="M13.3333 8.66665V3.21998C13.3333 2.17998 12.4866 1.33331 11.4466 1.33331C10.9466 1.33331 10.4666 1.53331 10.1133 1.88665L9.27998 2.71998C9.17331 2.68665 9.05998 2.66665 8.93998 2.66665C8.67331 2.66665 8.42665 2.74665 8.21998 2.87998L10.06 4.71998C10.1933 4.51331 10.2733 4.26665 10.2733 3.99998C10.2733 3.87998 10.2533 3.77331 10.2266 3.65998L11.06 2.82665C11.16 2.72665 11.3 2.66665 11.4466 2.66665C11.7533 2.66665 12 2.91331 12 3.21998V8.66665H7.43331C7.23331 8.52665 7.05331 8.36665 6.88665 8.18665L5.95331 7.15331C5.82665 7.01331 5.66665 6.89998 5.49331 6.81998C5.28665 6.71998 5.05998 6.66665 4.82665 6.66665C3.99998 6.67331 3.33331 7.33998 3.33331 8.16665V8.66665H1.33331V12.6666C1.33331 13.4 1.93331 14 2.66665 14C2.66665 14.3666 2.96665 14.6666 3.33331 14.6666H12.6666C13.0333 14.6666 13.3333 14.3666 13.3333 14C14.0666 14 14.6666 13.4 14.6666 12.6666V8.66665H13.3333Z"
      fill="currentColor"
    />
  </svg>
)

export default Bath
