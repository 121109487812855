import Spinner, { IconSize } from 'components/Spinner'
import React, { FC, ReactNode } from 'react'
import styles from './SpinnerWithMessage.module.scss'
import cx from 'classnames'

interface Props {
  className?: string
  message: ReactNode
  size?: IconSize
}

const SpinnerWithMessage: FC<Props> = ({ className, message, size = 'xl' }) => {
  return (
    <div className={cx(styles.wrapper, className)}>
      <div className="tw-flex tw-flex-col tw-items-center tw-space-y-5 tw-my-20">
        <div className="tw-flex tw-items-center tw-justify-center tw-w-24 tw-h-24">
          <Spinner size={size} />
        </div>
        <p className="tw-mt-6 tw-text-center tw-text-2xl">{message}</p>
      </div>
    </div>
  )
}

export default SpinnerWithMessage
