import React, { FC } from 'react'

interface TermsConsentMessageProps {
  className?: string
  verb?: string
}

const TermsConsentMessage: FC<TermsConsentMessageProps> = ({ className, verb = 'signing up' }) => {
  return (
    <p className={className}>
      {`By ${verb}, I agree to the Realm `}
      <a className="tw-underline" href="/privacy/" target="_blank" rel="noopener noreferrer">
        {'Privacy Policy'}
      </a>
      {' and '}
      <a className="tw-underline" href="/terms/" target="_blank" rel="noopener noreferrer">
        {'Terms of Service'}
      </a>
    </p>
  )
}

export default TermsConsentMessage
