import cx from 'classnames'
import React, { FC } from 'react'

interface InfoProps {
  className?: string
}

const Info: FC<InfoProps> = ({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className={cx('info', className)}>
    <path d="M0 0h24v24H0V0z" fill="none" />
    <path
      fill="currentColor"
      d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
    />
  </svg>
)

export default Info
