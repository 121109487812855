import React, { FC, useEffect, useState, useRef, ReactNode, PropsWithChildren } from 'react'

import { track } from 'utils/analytics'
import { poll } from 'utils/poll'
import { getJSON } from 'utils/fetch'

import XCircle from 'svgs/x-circle'
import SpinnerWithMessage from 'components/SpinnerWithMessage'

import styles from './CreatingProperty.module.scss'

const defaultLoadingStrings = [
  'Checking the provided address...',
  'Gathering financial info on your property...',
  'Analyzing your neighborhood history...',
  'Finishing up...',
]

interface PropertyPollResponse {
  error?: Record<string, unknown>
  result?: { id: number }
}

interface CreatingPropertyProps {
  className?: string
  onSuccess: (result: { id: number }) => void
  // No caller is relying on !token to throw an error. All make sure
  // to pass in a valid token.
  token?: string
  loadingStrings?: ReactNode[]
}

const CreatingProperty: FC<PropsWithChildren<CreatingPropertyProps>> = ({
  children,
  className,
  onSuccess,
  token,
  loadingStrings = defaultLoadingStrings,
}) => {
  const [loadingStep, setLoadingStep] = useState(0)
  const [error, setError] = useState<Error | null>(null)
  const lastToken = useRef<string | undefined>()

  useEffect(() => {
    // If we don't have a token yet, don't attempt to retrieve.
    if (!token) return

    // Only repeat if the token has actually changed
    if (token == lastToken.current) return
    lastToken.current = token

    let intervalId: number | null = null
    const _ = async () => {
      intervalId = window.setInterval(() => {
        setLoadingStep((step) => Math.min(loadingStrings.length - 1, step + 1))
      }, 2750)

      try {
        const pollResponse = await poll<PropertyPollResponse>({
          fn: async () => await getJSON<PropertyPollResponse>(`/api/v1/pollable_jobs/${token}`),
          validate: (pollResponse: PropertyPollResponse) => !!(pollResponse.error || pollResponse.result),
          interval: 2000,
          maxAttempts: 10,
        })
        onSuccess(pollResponse.result as any)
      } catch (e) {
        track('onboarding error', {
          error: 'error in polling',
        })
        setError(e)
      } finally {
        window.clearInterval(intervalId)
        intervalId = null
      }
    }
    _()
    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [onSuccess, token, loadingStrings.length])

  // If they passed in children, use that for display.
  if (children) return <>{children}</>

  return (
    <div className={className}>
      <div className="tw-flex tw-flex-col tw-items-center tw-space-y-5 tw-my-20">
        {error ? (
          <>
            <XCircle className="tw-w-24 tw-h-24 tw-text-red-500" />
            <p className="tw-mt-6">
              {
                "An error has occurred while processing your property. We've been notified and will take action to fix this issue."
              }
            </p>
            <a className={styles.button} href="/">
              {'Proceed'}
            </a>
          </>
        ) : (
          <>
            <SpinnerWithMessage className={styles.creatingProperty} message={loadingStrings[loadingStep]} />
          </>
        )}
      </div>
    </div>
  )
}

export default CreatingProperty
