import React, { FC } from 'react'
import classnames from 'classnames'
import MarkdownElement from 'markdown-to-jsx'
import type { MarkdownToJSX } from 'markdown-to-jsx'

import styles from './styles.module.scss'

const Markdown: FC<{
  [key: string]: any
  children: string
  className?: string
  options?: MarkdownToJSX.Options
}> = ({ className, ...other }) => (
  <div className={classnames(styles.markdown, className)}>
    <MarkdownElement {...other} />
  </div>
)

export default Markdown
