import React, { FC } from 'react'

interface Props {
  className?: string
}

const Svg: FC<Props> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <path
                  d="M0 0L24 0 24 24 0 24z"
                  transform="translate(-1356 -556) translate(200 472) translate(0 6) translate(0 47) translate(1156 31)"
                />
                <path
                  fill="currentColor"
                  fillRule="nonzero"
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
                  transform="translate(-1356 -556) translate(200 472) translate(0 6) translate(0 47) translate(1156 31)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default Svg
