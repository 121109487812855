import Close from 'svgs/property-plan/close'
import cx from 'classnames'
import FancyButton from './FancyButton'
import LightBox from './Lightbox'
import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import type { RedirectProps } from 'react-router-dom'

import styles from './NewModal.module.scss'

/* A new modal that utilizes our Overlay system */

export interface ModalButton {
  name: string
  secondary?: boolean
  disabled?: boolean
  type?: 'button' | 'submit' | 'reset'
  form?: string
  onClick?: () => void
  to?:
    | string
    | {
        pathname?: string
        search?: string
        state?: any
        hash?: string
        key?: string
      }
  href?: string
  push?: boolean
  from?: string
  path?: string
  exact?: boolean
  strict?: boolean
}

interface Props {
  className?: string
  title?: string | (() => ReactNode)
  buttons?: string | ModalButton | Array<string | ModalButton> | (() => ReactNode | Array<ReactNode>)
  onClose?: (() => void) | RedirectProps
}

function normalizeButton(button: string | ModalButton, onClose?: (() => void) | RedirectProps): ModalButton {
  if (typeof button === 'string') button = { name: button }
  // If button has no 'to', 'onClick', or 'form', then try to give it a way to function.
  if (!button.onClick && !button.to && !button.form) {
    button = Object.assign(button, typeof onClose === 'function' ? { onClick: onClose } : { ...onClose })
  }
  return button
}

const Modal: FC<PropsWithChildren<Props>> = ({ children, className, title, buttons, onClose }) => {
  const buttonsToUse: Array<ModalButton> | (() => ReactNode | Array<ReactNode>) = !buttons
    ? []
    : typeof buttons === 'function'
      ? buttons
      : buttons instanceof Array
        ? buttons.map((button) => normalizeButton(button, onClose))
        : [normalizeButton(buttons, onClose)]

  const [isShadowNeeded, setIsShadowNeeded] = useState<boolean>(false)
  const sectionRef = useRef<HTMLElement | null>(null)

  const computeNeedForShadow = useCallback(() => {
    const section = sectionRef.current
    if (!section) return

    setIsShadowNeeded(section.scrollHeight > section.clientHeight)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', computeNeedForShadow)
    return () => {
      window.removeEventListener('resize', computeNeedForShadow)
    }
  }, [computeNeedForShadow])

  useEffect(() => {
    const section = sectionRef.current
    if (!section) return

    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          computeNeedForShadow()
        }
      }
    })
    observer.observe(section, { childList: true, subtree: true })
    return () => {
      observer.disconnect()
    }
  }, [computeNeedForShadow])

  return (
    <LightBox className={cx(styles.modal, className)} onClose={onClose}>
      <header>
        {title ? <h2>{typeof title === 'string' ? title : title()}</h2> : null}
        {typeof onClose === 'function' ? (
          <button type="button" className={styles.close} onClick={onClose}>
            <Close />
          </button>
        ) : onClose ? (
          <Link className={styles.close} {...onClose}>
            <Close />
          </Link>
        ) : null}
      </header>
      <section ref={sectionRef}>{children}</section>
      {typeof buttonsToUse === 'function' || buttonsToUse.length > 0 ? (
        <footer className={cx({ [styles.withTopShadow]: isShadowNeeded })}>
          {typeof buttonsToUse === 'function'
            ? buttonsToUse()
            : buttonsToUse.map((button) => {
                const { secondary, name, ...others } = button
                return (
                  <FancyButton
                    key={name}
                    className={cx(styles.button, secondary ? styles.secondary : null)}
                    {...others}
                  >
                    {name}
                  </FancyButton>
                )
              })}
        </footer>
      ) : null}
    </LightBox>
  )
}

export default Modal
